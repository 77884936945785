import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import * as Cesium from "cesium";
import "./BuildingMap.css";
import Map3DGeneration from "./Map3DGeneration.tsx";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { SlSizeFullscreen } from "react-icons/sl";
import { API_URL } from "../../../actions/types.js";

Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNTE4YTU4MS0xZDIyLTRhMmEtYWM2Yy1hZDYxNjI3MGNiYzgiLCJpZCI6MTIyNTI5LCJpYXQiOjE2NzQ4OTU4Nzd9.a65a_dI7VQTEA1_OW1LhDqNwQ15bfyE3wzPcQmG2eac";

function BuildingsMap() {
  const [linesOpened, setLinesOpened] = useState(false);
  const [nodiOpened, setNodiOpened] = useState(false);
  const [impiantiOpened, setImpiantiOpened] = useState(false);
  const [agGridDataArchi, setAgGridDataArchi] = useState([]);
  const [agGridDataNodi, setAgGridDataNodi] = useState([]);
  const [agGridDataImpiantiGenerazione, setAgGridDataImpiantiGenerazione] = useState([]);
  const [archiSelectedIDs, setArchiSelectedIDs] = useState([]);
  const [nodiSelectedIDs, setNodiSelectedIDs] = useState([]);
  const mapRef = useRef()
  const nodiTableRef = useRef()
  const lineeTableRef = useRef()
  const impiantiTableRef = useRef()
  const [archiRowGroup, setArchiRowGroup] = useState({
    cabina_primaria_nome: true,
    sbarra: true,
    direttrice: true,
    nodo2: false,
  })
  const [nodiRowGroup, setNodiRowGroup] = useState({
    tipologia: true,
    categoria: false
  })
  const [impiantiRowGroup, setImpiantiRowGroup] = useState({
    type: false,
    sub_type: false,
    src: false,
    macro_src: false,
    convCE: false,
    convCIP6: false,
    convCV: false,
    convFERE: false,
    convRICOGE: false,
    convRID: false,
    convSSP: false,
    convTO: false,
  })


  useEffect(() => {
    axios.get(
      "https://geoserver.est.polito.it/geoserver/citta/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=citta%3Airen_archi&outputFormat=application%2Fjson&propertyName=id,cabina_primaria_nome,direttrice,sbarra,nodo1,nodo2"
    )
      .then((response) => response.data)
      .then((data) => {
        setAgGridDataArchi(data.features.map((f) => f.properties));
      });
    axios.get(
      "https://geoserver.est.polito.it/geoserver/citta/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=citta%3Airen_nodi&outputFormat=application%2Fjson&propertyName=codice_sap,denominazione,tipologia,categoria"
    )
      .then((response) => response.data)
      .then((data) => {
        setAgGridDataNodi(data.features.map((f) => f.properties));
      });
    axios.get(API_URL + "/data/impianti_di_generazione")
      .then((response) => response.data)
      .then((data) => {
        setAgGridDataImpiantiGenerazione(data);
      });
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div ref={mapRef} style={{ height: "100%", width: "100%", position: 'relative' }}>
        <Map3DGeneration archiSelectedIDs={archiSelectedIDs} nodiSelectedIDs={nodiSelectedIDs} />
        <div
          style={{ position: 'absolute', bottom: 5, right: 5, backgroundColor: 'white', cursor: 'pointer', width: 26, height: 26, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => {
            if (mapRef.current.style.height === '100%') {
              mapRef.current.style.height = '100vh'
              mapRef.current.style.width = '100vw'
              mapRef.current.style.position = 'absolute'
              mapRef.current.style.top = '0px'
              mapRef.current.style.left = '0px'
              mapRef.current.style.zIndex = '999'
            } else {
              mapRef.current.style.height = '100%'
              mapRef.current.style.width = '100%'
              mapRef.current.style.position = 'relative'
              lineeTableRef.current.style.zIndex = '10'
            }
          }}
        >
          <SlSizeFullscreen />
        </div>
      </div>
      <div
        ref={lineeTableRef}
        className="ag-theme-balham"
        style={{
          width: "50%",
          position: 'relative',
          display: linesOpened === false ? "none" : "block",
        }}
      >
        <AgGridReact
          rowData={agGridDataArchi}
          groupMultiAutoColumn={true}
          rowSelection='multiple'
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true, // <-- suppress pivot!
                  suppressColumnSelectAll: true
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ],
            defaultToolPanel: 'columns',
          }}
          onRowDataChanged={e => {
            e.api.selectAll()
          }}
          onGridSizeChanged={(params) => {
            params.columnApi.autoSizeAllColumns();
          }}
          onSelectionChanged={e => {
            const selectedData = e.api.getSelectedRows();
            setArchiSelectedIDs(selectedData.map(v => v.id))
          }}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { buttons: ["reset"] },
          }}
          suppressMovableColumns={true}
          autoGroupColumnDef={{
            minWidth: 150,
            cellRenderer: 'agGroupCellRenderer',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRendererParams: {
              checkbox: true,
              suppressCount: true,

            },
          }}
          onColumnRowGroupChanged={(params) => {
            const columnIDs = params.columns.map(col => col.colId)
            setArchiRowGroup(state => Object.fromEntries(Object.entries(state).map(([k, v]) => [k, columnIDs.includes(k)])))
            params.columnApi.autoSizeAllColumns();
          }}
        >
          <AgGridColumn
            field="id"
            minWidth={100}
            headerName="ID"
            checkboxSelection={(params) => !params.node.group}
            headerCheckboxSelection={(params) => params.columnApi.getRowGroupColumns().length === 0}
            headerCheckboxSelectionFilteredOnly={true}
          />
          <AgGridColumn
            field="cabina_primaria_nome"
            headerName="Primary cabin"
            enableRowGroup={true}
            rowGroup={archiRowGroup.cabina_primaria_nome}
            hide={archiRowGroup.cabina_primaria_nome}
          />
          <AgGridColumn field="sbarra" headerName="Bar" enableRowGroup={true} rowGroup={archiRowGroup.sbarra} hide={archiRowGroup.sbarra} />
          <AgGridColumn field="direttrice" headerName="Directrix" enableRowGroup={true} rowGroup={archiRowGroup.direttrice} hide={archiRowGroup.direttrice} />
          <AgGridColumn field="nodo1" headerName="Starting node" minWidth={125} />
          <AgGridColumn field="nodo2" headerName="Arrival node" minWidth={125} enableRowGroup={true} rowGroup={archiRowGroup.nodo2} hide={archiRowGroup.nodo2} />
        </AgGridReact>
        <div
          style={{ position: 'absolute', bottom: 5, right: 5, backgroundColor: 'white', cursor: 'pointer', width: 26, height: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}
          onClick={() => {
            if (lineeTableRef.current.style.width === '50%') {
              lineeTableRef.current.style.height = '100vh'
              lineeTableRef.current.style.width = '100vw'
              lineeTableRef.current.style.position = 'absolute'
              lineeTableRef.current.style.top = '0px'
              lineeTableRef.current.style.left = '0px'
              lineeTableRef.current.style.zIndex = '999'
            } else {
              lineeTableRef.current.style.height = '100%'
              lineeTableRef.current.style.width = '50%'
              lineeTableRef.current.style.position = 'relative'
              lineeTableRef.current.style.zIndex = '10'
            }
          }}
        >
          <SlSizeFullscreen />
        </div>
      </div>
      <div
        ref={nodiTableRef}
        className="ag-theme-balham"
        style={{
          width: "50%",
          position: 'relative',
          display: nodiOpened === false ? "none" : "block",
        }}
      >
        <AgGridReact
          rowData={agGridDataNodi}
          groupMultiAutoColumn={true}
          rowSelection='multiple'
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true, // <-- suppress pivot!
                  suppressColumnSelectAll: true
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ],
            defaultToolPanel: 'columns',
          }}
          onRowDataChanged={e => {
            e.api.selectAll()
          }}
          onGridSizeChanged={(params) => {
            params.columnApi.autoSizeAllColumns();
          }}
          onSelectionChanged={e => {
            const selectedData = e.api.getSelectedRows();
            setNodiSelectedIDs(selectedData.map(v => `iren_nodi.${v.codice_sap}`))
          }}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { buttons: ["reset"] },
          }}
          suppressMovableColumns={true}
          autoGroupColumnDef={{
            minWidth: 150,
            cellRenderer: 'agGroupCellRenderer',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRendererParams: {
              checkbox: true,
              suppressCount: true,

            },
          }}
          onColumnRowGroupChanged={(params) => {
            params.columnApi.autoSizeAllColumns();
            const columnIDs = params.columns.map(col => col.colId)
            setNodiRowGroup(state => Object.fromEntries(Object.entries(state).map(([k, v]) => [k, columnIDs.includes(k)])))
          }}
        >
          <AgGridColumn field="denominazione" headerName="Name"
            checkboxSelection={(params) => !params.node.group}
            headerCheckboxSelection={(params) => params.columnApi.getRowGroupColumns().length === 0}
            headerCheckboxSelectionFilteredOnly={true}
          />
          <AgGridColumn field="tipologia" headerName="Typology" enableRowGroup={true} rowGroup={nodiRowGroup.tipologia} hide={nodiRowGroup.tipologia} />
          <AgGridColumn field="categoria" headerName="Category" enableRowGroup={true} rowGroup={nodiRowGroup.categoria} hide={nodiRowGroup.categoria} />
          <AgGridColumn
            field="codice_sap"
            headerName="SAP Code"

          />
        </AgGridReact>
        <div
          style={{ position: 'absolute', bottom: 5, right: 5, backgroundColor: 'white', cursor: 'pointer', width: 26, height: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}
          onClick={() => {
            if (nodiTableRef.current.style.width === '50%') {
              nodiTableRef.current.style.height = '100vh'
              nodiTableRef.current.style.width = '100vw'
              nodiTableRef.current.style.position = 'absolute'
              nodiTableRef.current.style.top = '0px'
              nodiTableRef.current.style.left = '0px'
              nodiTableRef.current.style.zIndex = '999'
            } else {
              nodiTableRef.current.style.height = '100%'
              nodiTableRef.current.style.width = '50%'
              nodiTableRef.current.style.position = 'relative'
              nodiTableRef.current.style.zIndex = '10'
            }
          }}
        >
          <SlSizeFullscreen />
        </div>
      </div>

      <div
        ref={impiantiTableRef}
        className="ag-theme-balham"
        style={{
          width: "50%",
          position: 'relative',
          display: impiantiOpened === false ? "none" : "block",
        }}
      >
        <AgGridReact
          rowData={agGridDataImpiantiGenerazione}
          groupMultiAutoColumn={true}
          rowSelection='multiple'
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true, // <-- suppress pivot!
                  suppressColumnSelectAll: true
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ],
            defaultToolPanel: 'columns',
          }}
          onRowDataChanged={e => {
            e.api.selectAll()
          }}
          onGridSizeChanged={(params) => {
            params.columnApi.autoSizeAllColumns();
          }}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { buttons: ["reset"] },
          }}
          suppressMovableColumns={true}
          autoGroupColumnDef={{
            minWidth: 150,
            cellRenderer: 'agGroupCellRenderer',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRendererParams: {
              checkbox: true,
              suppressCount: true,

            },
          }}
          onColumnRowGroupChanged={(params) => {
            params.columnApi.autoSizeAllColumns();
            const columnIDs = params.columns.map(col => col.colId)
            setImpiantiRowGroup(state => Object.fromEntries(Object.entries(state).map(([k, v]) => [k, columnIDs.includes(k)])))
          }}
        >
          <AgGridColumn field="id" headerName="ID" />
          <AgGridColumn field="type" enableRowGroup={true} rowGroup={impiantiRowGroup.type} hide={impiantiRowGroup.type} />
          <AgGridColumn field="sub_type" headerName="Sub type" enableRowGroup={true} rowGroup={impiantiRowGroup.sub_type} hide={impiantiRowGroup.sub_type} />
          <AgGridColumn field="src" headerName="Source" enableRowGroup={true} rowGroup={impiantiRowGroup.src} hide={impiantiRowGroup.src} />
          <AgGridColumn field="macro_src" headerName="Macro src" enableRowGroup={true} rowGroup={impiantiRowGroup.macro_src} hide={impiantiRowGroup.macro_src} />
          <AgGridColumn field="address" />
          <AgGridColumn field="convCE" enableRowGroup={true} rowGroup={impiantiRowGroup.convCE} hide={impiantiRowGroup.convCE} />
          <AgGridColumn field="convCIP6" enableRowGroup={true} rowGroup={impiantiRowGroup.convCIP6} hide={impiantiRowGroup.convCIP6} />
          <AgGridColumn field="convCV" enableRowGroup={true} rowGroup={impiantiRowGroup.convCV} hide={impiantiRowGroup.convCV} />
          <AgGridColumn field="convFERE" enableRowGroup={true} rowGroup={impiantiRowGroup.convFERE} hide={impiantiRowGroup.convFERE} />
          <AgGridColumn field="convRICOGE" enableRowGroup={true} rowGroup={impiantiRowGroup.convRICOGE} hide={impiantiRowGroup.convRICOGE} />
          <AgGridColumn field="convRID" enableRowGroup={true} rowGroup={impiantiRowGroup.convRID} hide={impiantiRowGroup.convRID} />
          <AgGridColumn field="convSSP" enableRowGroup={true} rowGroup={impiantiRowGroup.convSSP} hide={impiantiRowGroup.convSSP} />
          <AgGridColumn field="convTO" enableRowGroup={true} rowGroup={impiantiRowGroup.convTO} hide={impiantiRowGroup.convTO} />
          <AgGridColumn field="pow" headerName="Power" enableValue={true} />

        </AgGridReact>
        <div
          style={{ position: 'absolute', bottom: 5, right: 5, backgroundColor: 'white', cursor: 'pointer', width: 26, height: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}
          onClick={() => {
            if (impiantiTableRef.current.style.width === '50%') {
              impiantiTableRef.current.style.height = '100vh'
              impiantiTableRef.current.style.width = '100vw'
              impiantiTableRef.current.style.position = 'absolute'
              impiantiTableRef.current.style.top = '0px'
              impiantiTableRef.current.style.left = '0px'
              impiantiTableRef.current.style.zIndex = '999'
            } else {
              impiantiTableRef.current.style.height = '100%'
              impiantiTableRef.current.style.width = '50%'
              impiantiTableRef.current.style.position = 'relative'
              impiantiTableRef.current.style.zIndex = '10'
            }
          }}
        >
          <SlSizeFullscreen />
        </div>
      </div>

      <div className="ag-theme-balham">
        <div
          className="ag-side-bar ag-unselectable ag-side-bar-right ag-focus-managed"
          style={{ backgroundColor: "white", height: "100%", fontSize: 14, fontWeight: 'bold' }}
        >
          <div className="ag-side-buttons">
            <div
              className="ag-side-button"
              role="presentation"
              onClick={() => {
                setNodiOpened(false)
                setImpiantiOpened(false)
                setLinesOpened(!linesOpened)
              }}
            >
              <button
                type="button"
                tabIndex="-1"
                role="tab"
                className="ag-side-button-button"
              >
                <div className="ag-side-button-icon-wrapper" aria-hidden="true">
                  <span
                    className="ag-icon ag-icon-filter"
                    unselectable="on"
                    role="presentation"
                  />
                </div>
                <span className="ag-side-button-label">Lines</span>
              </button>
            </div>
            <div
              className="ag-side-button"
              role="presentation"
              onClick={() => {
                setLinesOpened(false)
                setImpiantiOpened(false)
                setNodiOpened(!nodiOpened)
              }
              }
            >
              <button
                type="button"
                tabIndex="-1"
                role="tab"
                className="ag-side-button-button"
              >
                <div className="ag-side-button-icon-wrapper" aria-hidden="true">
                  <span
                    className="ag-icon ag-icon-filter"
                    unselectable="on"
                    role="presentation"
                  />
                </div>
                <span className="ag-side-button-label">Nodes</span>
              </button>
            </div>
            <div
              className="ag-side-button"
              role="presentation"
              onClick={() => {
                setLinesOpened(false)
                setNodiOpened(false)
                setImpiantiOpened(!impiantiOpened)
              }
              }
            >
              <button
                type="button"
                tabIndex="-1"
                role="tab"
                className="ag-side-button-button"
              >
                <div className="ag-side-button-icon-wrapper" aria-hidden="true">
                  <span
                    className="ag-icon ag-icon-filter"
                    unselectable="on"
                    role="presentation"
                  />
                </div>
                <span className="ag-side-button-label">Generation plants</span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildingsMap;
