/* eslint-disable no-template-curly-in-string */
import React, { useRef, useEffect, useState, useMemo } from "react";
import chroma from "chroma-js";
import { renderToString } from "react-dom/server";
import * as Cesium from "cesium";
import { API_URL } from "../../../actions/types";
import axios from "axios";
import gearth from "./gearth.png"

export default function Photovoltaic(params) {
    const container = useRef<HTMLDivElement>(null);
    const legend = useRef<HTMLDivElement>(null);
    const [tilesetTorino, setTilesetTorino] = useState<Cesium.Cesium3DTileset>()
    const mapHandler = useRef<Cesium.ScreenSpaceEventHandler>()
    var features = useMemo(() => ({}), [])
    const google = useRef<Cesium.Cesium3DTileset>();

    const viewer = useMemo(() => {
        if (container.current === null) return
        const viewer = new Cesium.Viewer(container.current, {
            timeline: false,
            animation: false,
            baseLayerPicker: false,
            homeButton: false,
            navigationHelpButton: false,
            infoBox: true,
            scene3DOnly: true,
            geocoder: false,
            shadows: false,
            selectionIndicator: true,
            fullscreenButton: false,
        });
        viewer.scene.globe.depthTestAgainstTerrain = true
        viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(7.78, 45.02, 10000),
            orientation: {
                heading: Cesium.Math.toRadians(-60.0),
                pitch: Cesium.Math.toRadians(-45.0),
            },
        });
        viewer.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        );
        viewer.imageryLayers.removeAll();
        const osmImagery = new Cesium.OpenStreetMapImageryProvider({
            url: "https://a.tile.openstreetmap.org/",
        });
        viewer.imageryLayers.addImageryProvider(osmImagery);
        return viewer
    }, [container.current])

    const computeEPVClass = (epv) => {
        switch (true) {
            case epv / 46518 > 0.03926420:
                return 10;
            case epv / 46518 > 0.01733515:
                return 9;
            case epv / 46518 > 0.00928453:
                return 8;
            case epv / 46518 > 0.00541938:
                return 7;
            case epv / 46518 > 0.00330193:
                return 6;
            case epv / 46518 > 0.00206155:
                return 5;
            case epv / 46518 > 0.00075454:
                return 4;
            case epv / 46518 > 0.00039124:
                return 3;
            case epv / 46518 > 0.00012468:
                return 2;
            case epv / 46518 > 0:
                return 1;

            default:
                return 0;
        }
    };

    const getTilesetTorino = async () => {
        const torino = await Cesium.Cesium3DTileset.fromUrl(`${API_URL.replace("api", "")}tilesets/torino/tileset.json`, {
            maximumScreenSpaceError: 1,
            lightColor: new Cesium.Cartesian3(10, 10, 10),
        })

        torino.tileLoad.addEventListener((tile: Cesium.Cesium3DTile) => {
            const content = tile.content;
            const featuresLength = content.featuresLength;
            for (let i = 0; i < featuresLength; i++) {
                const feature = content.getFeature(i);
                const feature_id = feature.getProperty("Identifier");
                features[feature_id] = feature;;
                feature.setProperty("epv_class", computeEPVClass(feature.getProperty("Producibilità [MWh/anno]")));
            }
        });
        setTilesetTorino(torino)
    }


    useEffect(() => {
        if (tilesetTorino === undefined || viewer === undefined) return
        tilesetTorino.style = new Cesium.Cesium3DTileStyle({
            color: {
                conditions: [
                    ["${selected} === true", 'color("aqua")'],
                    ["${epv_class} === 1", 'color("#5ea7b1")'],
                    ["${epv_class} === 2", 'color("#91cba8")'],
                    ["${epv_class} === 3", 'color("#bce4a9")'],
                    ["${epv_class} === 4", 'color("#ddf1b4")'],
                    ["${epv_class} === 5", 'color("#ffffbf")'],
                    ["${epv_class} === 6", 'color("#fedf99")'],
                    ["${epv_class} === 7", 'color("#fdbe74")'],
                    ["${epv_class} === 8", 'color("#f59053")'],
                    ["${epv_class} === 9", 'color("#e65538")'],
                    ["${epv_class} === 10", 'color("#d7191c")'],
                ],
            },
        })
        viewer.scene.primitives.add(tilesetTorino);
    }, [tilesetTorino, viewer])

    const addLegendHTML = () => {
        const colors = ["#2b83ba", "#5ea7b1", "#91cba8", "#bce4a9", "#ddf1b4", "#ffffbf", "#fedf99", "#fdbe74", "#f59053", "#e65538", "#d7191c"];
        const colorScale = chroma.scale(colors);
        legend.current!.innerHTML = renderToString(
            <div style={{ fontSize: 14 }}>
                <div
                    style={{
                        fontWeight: "bold",
                        width: "100%",
                        textAlign: "center",
                        marginBottom: 5,
                    }}
                >
                    Producibility (MWh/anno)
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    {Array(100)
                        .fill(0)
                        .map((v, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        height: 10,
                                        width: 4,
                                        backgroundColor: colorScale(index / 100).hex(),
                                    }}
                                />
                            );
                        })}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 13,
                    }}
                >
                    <div style={{ width: 50, textAlign: "left" }}>0</div>
                    <div style={{ width: 50, textAlign: "center" }}>
                        20
                    </div>
                    <div style={{ width: 50, textAlign: "center" }}>
                        100
                    </div>
                    <div style={{ width: 50, textAlign: "center" }}>
                        500
                    </div>
                    <div style={{ width: 50, textAlign: "right" }}>
                        1000+
                    </div>
                </div>
            </div>
        );
    };

    const addClickHandler = () => {
        viewer?.entities.removeAll();
        mapHandler.current?.destroy();
        mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer?.canvas);
        mapHandler.current?.setInputAction(movement => {
            const cartesian = viewer!.scene.pickPosition(movement.position);
            const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
            const longitude = Cesium.Math.toDegrees(cartographic.longitude)
            const latitude = Cesium.Math.toDegrees(cartographic.latitude)

            axios.post(`${API_URL}/data/building_details/`, { longitude, latitude }).then(response => {
                viewer?.entities.removeAll()
                const data = response.data
                if (data === undefined || data === null || data === '') return
                let description = '<table class="cesium-infoBox-defaultTable"><tbody>';
                Object.keys(data)
                    .filter(fieldName => fieldName !== 'footprint')
                    .forEach(fieldName => {
                        description += `<tr><th>${fieldName}</th><td>`;
                        description += `${data[fieldName]}</td></tr>`;
                    })
                description += `</tbody></table>`;

                const targetHighlight = new Cesium.Entity({
                    description,
                    name: 'Building description',
                    polygon: {
                        hierarchy: Cesium.Cartesian3.fromDegreesArray(
                            data.footprint.flat(2)
                        ),
                        material: Cesium.Color.YELLOW.withAlpha(0.4),
                        classificationType: Cesium.ClassificationType.BOTH,
                    },
                });


                viewer!.entities.add(targetHighlight);
                viewer!.selectedEntity = targetHighlight
            })
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }

    useEffect(() => {
        getTilesetTorino()
        addLegendHTML()
        generateGoogleInstance()

    }, [])
    useEffect(() => {
        addClickHandler()
    }, [viewer])


    const generateGoogleInstance = () => {
        Cesium.Cesium3DTileset.fromIonAssetId(2275207, {
            shadows: Cesium.ShadowMode.DISABLED,
            skipLevelOfDetail: true,
            baseScreenSpaceError: 10240,
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: false,
            cullWithChildrenBounds: true
        }).then(g => {
            google.current = g;
        })
    }

    function toggleGoogle() {
        if (viewer?.scene.primitives.contains(google!)) {
            viewer?.scene.primitives.remove(google!)
            generateGoogleInstance()
        } else {
            viewer?.scene.primitives.add(google!)
        }
    }

    return (
        <div
            ref={container}
            style={{ width: "100%", height: "100%", position: "relative" }}
        >
            <div
                ref={legend}
                style={{
                    position: "absolute",
                    bottom: ".5em",
                    left: ".5em",
                    backgroundColor: "white",
                    borderRadius: 5,
                    padding: 10,
                    zIndex: 99,
                    pointerEvents: "none",
                }}
            />
        </div>
    );
}