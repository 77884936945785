import React, { useRef, useEffect, useState } from "react";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { API_URL } from "../../../actions/types";
import axios from "axios";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function Map3DBuildings({ selectedFeaturesOBJ }) {
  const [style, setStyle] = useState("buildings-type");
  const mapHandler = useRef<Cesium.ScreenSpaceEventHandler>()
  const google = useRef<Cesium.Cesium3DTileset>();

  const container = useRef<HTMLDivElement>(null);
  const viewer = useRef<Cesium.Viewer>();
  const tilesetTorino = useRef<Cesium.Cesium3DTileset>();

  const features = useRef({});

  const addTorinoTileset = async () => {
    tilesetTorino.current = await Cesium.Cesium3DTileset.fromUrl(`${API_URL.replace("api", "")}tilesets/torino/tileset.json`, {
      maximumScreenSpaceError: 1,
      lightColor: new Cesium.Cartesian3(10, 10, 10),
    });

    tilesetTorino.current.style = new Cesium.Cesium3DTileStyle({
      color: {
        conditions: [
          // eslint-disable-next-line no-template-curly-in-string
          ["${selected} === true", 'color("aqua")'],
          // eslint-disable-next-line no-template-curly-in-string
          [
            '${feature["Tipologia edificio"]} === "Residenziale"',
            'color("#54BA54")',
          ],
          // eslint-disable-next-line no-template-curly-in-string
          [
            '${feature["Tipologia edificio"]} === "Servizi"',
            'color("#DC9523")',
          ],
          // eslint-disable-next-line no-template-curly-in-string
          [
            '${feature["Tipologia edificio"]} === "Produzione"',
            'color("#D04747")',
          ],
        ],
      },
    });
    viewer.current?.scene.primitives.add(tilesetTorino.current);
    tilesetTorino.current!.tileLoad.addEventListener((tile: Cesium.Cesium3DTile) => {
      const content = tile.content;
      const featuresLength = content.featuresLength;
      for (let i = 0; i < featuresLength; i++) {
        const feature = content.getFeature(i);
        const feature_id = feature.getProperty("Identifier");
        features.current[feature_id] = feature;
        features.current[feature_id].setProperty(
          "selected",
          selectedFeaturesOBJ[feature_id] ?? false
        );
        feature.setProperty(
          "epv_class",
          computeEPVClass(feature.getProperty("Producibilità [kWh/anno]"))
        );
      }
    });


  };

  useEffect(() => {
    if (!tilesetTorino.current || !selectedFeaturesOBJ) return;
    tilesetTorino.current!.tileLoad._listeners = [];
    tilesetTorino.current!.tileLoad._scopes = [];
    tilesetTorino.current!.tileLoad.addEventListener((tile) => {
      const content = tile.content;
      const featuresLength = content.featuresLength;
      for (let i = 0; i < featuresLength; i++) {
        const feature = content.getFeature(i);
        const feature_id = feature.getProperty("Identifier");
        features.current[feature_id] = feature;
        features.current[feature_id].setProperty(
          "selected",
          selectedFeaturesOBJ[feature_id] ?? false
        );
        feature.setProperty(
          "epv_class",
          computeEPVClass(feature.getProperty("Producibilità [kWh/anno]"))
        );
      }
    });
    Object.entries(features.current).forEach(([id, feature]) => {
      feature.setProperty("selected", selectedFeaturesOBJ[id] ?? false);
    });
  }, [selectedFeaturesOBJ]);

  const OSMImagery = () => {
    viewer.current?.imageryLayers.removeAll();
    const osmImagery = new Cesium.OpenStreetMapImageryProvider({
      url: "https://a.tile.openstreetmap.org/",
    });
    viewer.current?.imageryLayers.addImageryProvider(osmImagery);
  };



  const computeEPVClass = (epv) => {
    switch (true) {
      case epv / 11285600 > 0.225:
        return 10;
      case epv / 11285600 > 0.2:
        return 9;
      case epv / 11285600 > 0.175:
        return 8;
      case epv / 11285600 > 0.15:
        return 7;
      case epv / 11285600 > 0.125:
        return 6;
      case epv / 11285600 > 0.1:
        return 5;
      case epv / 11285600 > 0.075:
        return 4;
      case epv / 11285600 > 0.05:
        return 3;
      case epv / 11285600 > 0.025:
        return 2;
      case epv / 11285600 > 0:
        return 1;

      default:
        return 0;
    }
  };

  const addClickHandler = () => {
    viewer.current?.entities.removeAll();
    mapHandler.current?.destroy();
    mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current?.canvas);
    mapHandler.current?.setInputAction(movement => {
      const cartesian = viewer.current!.scene.pickPosition(movement.position);
      const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
      const longitude = Cesium.Math.toDegrees(cartographic.longitude)
      const latitude = Cesium.Math.toDegrees(cartographic.latitude)

      axios.post(`${API_URL}/data/building_details/`, { longitude, latitude }).then(response => {
        viewer.current?.entities.removeAll()
        const data = response.data
        if (data === undefined || data === null || data === '') return
        let description = '<table class="cesium-infoBox-defaultTable"><tbody>';
        Object.keys(data)
          .filter(fieldName => fieldName !== 'Footprint')
          .forEach(fieldName => {
            description += `<tr><th>${fieldName}</th><td>`;
            description += `${data[fieldName]}</td></tr>`;
          })
        description += `</tbody></table>`;

        const targetHighlight = new Cesium.Entity({
          description,
          name: 'Building description',
          polygon: {
            hierarchy: Cesium.Cartesian3.fromDegreesArray(
              data.Footprint.flat(2)
            ),
            material: Cesium.Color.YELLOW.withAlpha(0.4),
            classificationType: Cesium.ClassificationType.BOTH,
          },
        });


        viewer.current!.entities.add(targetHighlight);
        viewer.current!.selectedEntity = targetHighlight
      })
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  useEffect(() => {
    if (!container.current || viewer.current) return;

    viewer.current = new Cesium.Viewer(container.current, {
      timeline: false,
      animation: false,
      baseLayerPicker: false,
      homeButton: false,
      navigationHelpButton: false,
      infoBox: true,
      scene3DOnly: true,
      geocoder: false,
      shadows: false,
      selectionIndicator: false,
      fullscreenButton: false,
    });
    viewer.current.scene.globe.depthTestAgainstTerrain = true

    viewer.current.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(7.78, 45.02, 10000),
      orientation: {
        heading: Cesium.Math.toRadians(-60.0),
        pitch: Cesium.Math.toRadians(-45.0),
      },
    });
    viewer.current.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    addTorinoTileset();
    OSMImagery();
    generateGoogleInstance()
    addClickHandler()
  }, []);

  useEffect(() => {
    if (!tilesetTorino.current) return;
    tilesetTorino.current!.style = new Cesium.Cesium3DTileStyle({
      color: {
        conditions: [
          // eslint-disable-next-line no-template-curly-in-string
          ["${selected} === true", 'color("aqua")'],
          // eslint-disable-next-line no-template-curly-in-string
          [
            '${feature["Tipologia edificio"]} === "Residenziale"',
            'color("#54BA54")',
          ],
          // eslint-disable-next-line no-template-curly-in-string
          [
            '${feature["Tipologia edificio"]} === "Servizi"',
            'color("#DC9523")',
          ],
          // eslint-disable-next-line no-template-curly-in-string
          [
            '${feature["Tipologia edificio"]} === "Produzione"',
            'color("#D04747")',
          ],
        ],
      },
    });
  }, [style]);

  const generateGoogleInstance = () => {
    Cesium.Cesium3DTileset.fromIonAssetId(2275207, {
      shadows: Cesium.ShadowMode.DISABLED,
      skipLevelOfDetail: true,
      baseScreenSpaceError: 10240,
      skipScreenSpaceErrorFactor: 16,
      skipLevels: 1,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      cullWithChildrenBounds: true
    }).then(g => {
      google.current = g;
      g.show = false
      viewer.current?.scene.primitives.add(g)
    })
  }

  function toggleGoogle(value) {
    google.current!.show = value
  }

  return (
    <div
      ref={container}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div style={{ position: 'absolute', top: '0.5em', right: '0.5em', backdropFilter: 'rgba(0, 0, 0, 0)', pointerEvents: 'auto', zIndex: 1 }}>
        <div style={{ backgroundColor: "white", cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5 }}>
          <div style={{ marginLeft: 20 }}><FormControlLabel control={<Checkbox onChange={e => toggleGoogle(e.target.checked)} />} label='Google "skin"' /></div>
        </div>
      </div>
    </div>
  );
}
